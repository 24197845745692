@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@fontsource/inter/index.css';
@import '@fontsource/roboto';


  .text-shadow-xl {
    text-shadow: 
      2px 2px 0 #fff, 
      -2px -2px 0 #fff, 
      2px -2px 0 #fff, 
      -2px 2px 0 #fff, 
      2px 0 0 #fff, 
      0 2px 0 #fff, 
      -2px 0 0 #fff, 
      0 -2px 0 #fff;
  }

  .text-shadow-md {
    text-shadow: 
      1px 1px 0 rgba(255, 255, 255, 0.75), 
      -1px -1px 0 rgba(255, 255, 255, 0.75), 
      1px -1px 0 rgba(255, 255, 255, 0.75), 
      -1px 1px 0 rgba(255, 255, 255, 0.75), 
      1px 0 0 rgba(255, 255, 255, 0.75), 
      0 1px 0 rgba(255, 255, 255, 0.75), 
      -1px 0 0 rgba(255, 255, 255, 0.75), 
      0 -1px 0 rgba(255, 255, 255, 0.75);
  }


